
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { CircularIconVariant } from '@/components/atoms/AtomCircularIcon.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomDropdownAddressSearch from '@/components/atoms/AtomDropdownAddressSearch.vue';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import AtomNotification, { NotificationVariant } from '@/components/atoms/AtomNotification.vue';
import MoleculeHereMap from '@/components/molecules/maps/MoleculeHereMap.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';

import { createMarkerBase, createMarker, resizeMap } from '@twogo/here-maps/src/lib/HereMapsService';

import BaseMarkerModel from '@twogo/here-maps/src/lib/models/BaseMarkerModel';
import { CoordinatesModel } from '@/models/common/CoordinatesModel';
import GenericAddressModel from '@/models/geolocation/GenericAddressModel';
import PoiPutModel from '@/models/geolocation/poi/PoiPutModel';
import { PoiModel } from '@/models/geolocation/poi/PoiModel';

import PoiTypesEnum from '@/enums/geolocation/PoiTypesEnum';
import DefaultPoiEnum from '@/enums/geolocation/DefaultPoiEnum';

import { getGenericAddress } from '@/services/geolocation/genericAddressBuilder';
import { browserGeolocation, revGeoCode } from '@/services/geolocation/geolocationService';
import { quicksearch } from '@/api/geolocation/quicksearch/quicksearchApi';

import locationPin from '@/assets/images/icons/icon-pin-start.svg';

import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import { LOCATION_REGEX } from '@/common/config';
import i18n from '@/i18n';

export const ORGANISM_NAME = 'OrganismEditPoi';

export default Vue.extend({
  name: ORGANISM_NAME,
  components: {
    AtomButton,
    AtomDropdownAddressSearch,
    AtomNotification,
    AtomTextInput,
    MoleculeHereMap,
    OrganismPageSubHeader,
  },
  data: () => ({
    id: '',
    name: '',
    comment: '',
    poiType: '',
    address: undefined as any,
    hereMap: undefined as any,
    hereMapGroup: undefined as any,
    geoSuggestions: [] as GenericAddressModel[],
    disableNameInput: false,
    isNameInUse: false,
    isNameFieldValid: true,
    canBeDeleted: true,
    ButtonVariant,
    CircularIconVariant,
    NotificationVariant,
    inputRequirements: [{
      validator: (input) => LOCATION_REGEX.test(input),
      showOnValid: true,
      description: i18n.t('myLocations.nameValidation').toString(),
    }],
    locationHintMarker: undefined as any,
    isLoading: false,
    isLoadingDelete: false,
  }),
  computed: {
    ...mapGetters('user', ['getUserId']),
    ...mapGetters('poi', [
      'getPoi',
      'getHomePoi',
      'isPoiNameInUse',
      'isPoiNameReserved',
      'getPoiById',
    ]),
    isFormValid(): boolean {
      const { isNameFieldValid, isNameInUse, address } = this;

      return isNameFieldValid && !isNameInUse && address;
    },
    isPoiNameTaken(): boolean {
      const { name, isPoiNameInUse } = this;
      return name && isPoiNameInUse(name);
    },
    getMarker(): BaseMarkerModel[] {
      const { address } = this;
      const markers = [] as BaseMarkerModel[];

      if (address) {
        markers.push(createMarkerBase(locationPin, address.position, 'create-POI'));
      }

      return markers;
    },
  },
  methods: {
    ...mapActions('poi', ['editPoi', 'addPoi', 'deletePoi']),
    async getGeoSuggestions(location: string): Promise<void> {
      if (location.length >= 3) {
        const { data } = await quicksearch(location);
        this.geoSuggestions = data;
      } else {
        this.geoSuggestions = [];
      }
    },
    async searchAddressByCoordinates(coordinates: CoordinatesModel): Promise<void> {
      const {
        data: [address],
      } = await revGeoCode({ at: coordinates });

      this.address = address;
    },
    async localizeMe(): Promise<void> {
      const { success, coordinates, message = '' } = await browserGeolocation();
      if (success && coordinates) {
        this.searchAddressByCoordinates(coordinates);
      } else {
        toast(this.$bvToast, message, ToastVariants.DANGER);
      }
      this.searchAddressByCoordinates({ lat: 49.14975, lng: 9.29862 });
    },
    updatePoi(): void {
      this.isLoading = true;
      this.editPoi({
        ownerId: this.getUserId,
        poiId: this.id,
        name: this.name,
        comment: this.comment,
        address: this.address?.address.label,
        location: {
          latitude: this.address?.position.lat,
          longitude: this.address?.position.lng,
        },
        type: PoiTypesEnum.USER,
      } as PoiPutModel)
        .then(() => {
          toast(this.$bvToast, this.$t('myLocations.messages.success').toString());
          setTimeout(() => {
            this.$router.push(`/${this.$i18n.locale}/locations`);
          }, 1500);
        })
        .catch(() => {
          toast(
            this.$bvToast,
            this.$t('myLocations.messages.error').toString(),
            ToastVariants.DANGER,
          );
        }).finally(() => {
          this.isLoading = false;
        });
    },
    removePoi(): void {
      this.isLoadingDelete = true;
      this.deletePoi({ id: this.id, type: PoiTypesEnum.USER })
        .then(() => {
          toast(this.$bvToast, this.$t('myLocations.messages.deleted').toString());
          setTimeout(() => {
            this.$router.push(`/${this.$i18n.locale}/locations`);
          }, 1500);
        })
        .catch(() => {
          toast(
            this.$bvToast,
            this.$t('myLocations.messages.error').toString(),
            ToastVariants.DANGER,
          );
        }).finally(() => {
          this.isLoadingDelete = false;
        });
    },
    validateName(status: boolean) {
      this.isNameFieldValid = status;
    },
    locationHover(coordinates) {
      if (this.hereMap) {
        if (!this.locationHintMarker) {
          this.locationHintMarker = createMarker({ coordinates, icon: locationPin as any });
          this.hereMap.addObject(this.locationHintMarker);
        }
        this.locationHintMarker.setGeometry(coordinates);
        this.hereMap.setCenter(coordinates);
        this.hereMap.setZoom(14);
      }
    },
    locationHoverEnd() {
      if (this.locationHintMarker) {
        this.hereMap.removeObject(this.locationHintMarker);

        this.locationHintMarker = undefined as any;
        resizeMap(this.hereMap, this.hereMapGroup);
      }
    },
  },
  watch: {
    name(value, prevValue) {
      if (prevValue !== '' && (this.isPoiNameInUse(value) || this.isPoiNameReserved(value))) {
        this.isNameInUse = true;
      } else {
        this.isNameInUse = false;
      }
    },
  },
  async beforeMount() {
    const POI = this.getPoiById(this.$router.currentRoute.params.id) as PoiModel;
    if (POI) {
      this.id = POI.id;
      this.name = POI.name;
      this.poiType = POI.poiType;
      this.comment = POI.comment;
      this.address = getGenericAddress(POI);
      if ([DefaultPoiEnum.HOME, DefaultPoiEnum.WORK].includes(POI.name as DefaultPoiEnum)) {
        this.disableNameInput = true;
        this.isNameInUse = false;
        this.canBeDeleted = false;
      }
    } else {
      this.$router.push(`/${this.$i18n.locale}/locations`);
    }
  },
});
