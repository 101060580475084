
import Vue from 'vue';
import i18n from '@/i18n';
import TemplateMyLocations from '@/components/templates/locations/TemplateMyLocations.vue';

export default Vue.extend({
  name: 'MyLocations',
  components: { TemplateMyLocations },
  metaInfo: {
    title: ` - ${i18n.t('tabs.locations')}`,
  },
});
