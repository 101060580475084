
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { CircularIconVariant } from '@/components/atoms/AtomCircularIcon.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomCircularButton from '@/components/atoms/AtomCircularButton.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import MoleculeDetailsCard from '@/components/molecules/cards/MoleculeDetailsCard.vue';
import MoleculeHereMap from '@/components/molecules/maps/MoleculeHereMap.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';

import CoordinatesModel from '@twogo/geolocation/src/lib/models/CoordinatesModel';
import BaseMarkerModel from '@twogo/here-maps/src/lib/models/BaseMarkerModel';
import { PoiModel } from '@/models/geolocation/poi/PoiModel';

import { createMarkerBase, resizeMap } from '@twogo/here-maps/src/lib/HereMapsService';

import locationPin from '@/assets/images/icons/icon-pin-start.svg';
import homePin from '@/assets/images/icons/icon-pin-home.svg';
import companyPin from '@/assets/images/icons/icon-pin-company.svg';
import suitcasePin from '@/assets/images/icons/icon-pin-work.svg';
import favoritePin from '@/assets/images/icons/icon-pin-favorite.svg';

import PoiTypesEnum from '@/enums/geolocation/PoiTypesEnum';
import DefaultPoiEnum from '@/enums/geolocation/DefaultPoiEnum';

export const ORGANISM_NAME = 'OrganismPoiList';

interface PoiCard {
  id: string;
  icon: string;
  heading: string;
  subheading: string;
  extra: string;
  coordinates?: CoordinatesModel;
}

export default Vue.extend({
  name: ORGANISM_NAME,
  components: {
    AtomButton,
    AtomCircularButton,
    AtomSvgIcon,
    MoleculeDetailsCard,
    MoleculeHereMap,
    OrganismPageSubHeader,
  },
  data: () => ({
    hereMap: undefined as any,
    hereMapGroup: undefined as any,
    editMode: false,
    selectedPoi: '',
    UserPOI: [] as PoiCard[],
    OrganizationPOI: [] as PoiCard[],
    PoiTypesEnum,
    ButtonVariant,
    CircularIconVariant,
  }),
  computed: {
    ...mapGetters('poi', ['getPoi', 'getHomePoi', 'isPoiNameInUse']),
    getMarkerLocations(): BaseMarkerModel[] {
      const markers = [] as BaseMarkerModel[];
      const { getPoi: locations }: { getPoi: PoiModel[] } = this;

      locations.forEach((location) => {
        markers.push(
          createMarkerBase(
            this.getSvg(location.poiType, location.name),
            location.location,
          ),
        );
      });

      return markers;
    },
    mapCenter(): CoordinatesModel {
      return (this.getHomePoi || { location: { lat: 52.520008, lng: 13.404954 } }).location;
    },
  },
  methods: {
    onMarkersDrop() {
      setTimeout(() => {
        resizeMap(this.hereMap, this.hereMapGroup);
      }, 700);
    },
    setHere(map, group) {
      this.hereMap = map;
      this.hereMapGroup = group;
    },
    getPoiCards(poiTypes: PoiTypesEnum[] = []) {
      const { getPoi: locations }: { getPoi: PoiModel[] } = this;

      return locations
        .filter(({ poiType }) => poiTypes.includes(poiType))
        .map((location) => ({
          id: location.id,
          icon: this.getSvgPath(location.poiType, location.name),
          heading: location.name,
          subheading: location.address.label,
          extra: location.comment,
          coordinates: location.location,
        }));
    },
    setPoiCards(): void {
      const { setEmptyPoiCards, getPoiCards, UserPOI } = this;

      setEmptyPoiCards();
      this.UserPOI = UserPOI.concat(getPoiCards([PoiTypesEnum.USER]));
      this.OrganizationPOI = getPoiCards([PoiTypesEnum.COMPANY, PoiTypesEnum.SITE]);
      this.sortPoiCards();
    },
    setEmptyPoiCards(): void {
      const { isPoiNameInUse, getSvgPath } = this;

      [DefaultPoiEnum.HOME, DefaultPoiEnum.WORK].forEach((poiName) => {
        if (!isPoiNameInUse(poiName)) {
          this.UserPOI.push({
            id: '',
            icon: getSvgPath(PoiTypesEnum.USER, poiName),
            heading: poiName,
            subheading: '',
            extra: '',
          });
        }
      });
    },
    getSvg(poiType: PoiTypesEnum, poiName: string) {
      if ([PoiTypesEnum.COMPANY, PoiTypesEnum.SITE].includes(poiType)) {
        return companyPin;
      }
      if (poiType === PoiTypesEnum.USER) {
        if (poiName === DefaultPoiEnum.WORK) return suitcasePin;
        if (poiName === DefaultPoiEnum.HOME) return homePin;
        return favoritePin;
      }
      return locationPin;
    },
    getSvgPath(poiType: PoiTypesEnum, poiName: string): string {
      if ([PoiTypesEnum.COMPANY, PoiTypesEnum.SITE].includes(poiType)) {
        return 'icons/icon-company.svg';
      }
      if (poiType === PoiTypesEnum.USER) {
        if (poiName === DefaultPoiEnum.WORK) return 'icons/icon-suitcase.svg';
        if (poiName === DefaultPoiEnum.HOME) return 'icons/icon-home.svg';
        return 'icons/icon-heart.svg';
      }
      return 'icons/icon-location-pin.svg';
    },
    toggleMapZoom(coordinates: CoordinatesModel|undefined): void {
      if (!coordinates) return;
      const c = `${coordinates.lat};${coordinates.lng}`;
      if (c === this.selectedPoi) {
        resizeMap(this.hereMap, this.hereMapGroup);
        this.selectedPoi = '';
      } else {
        this.hereMap.setCenter(coordinates).setZoom(16);
        this.selectedPoi = c;
      }
    },
    sortPoiCards(): void {
      this.UserPOI.sort((a, b) => a.heading.localeCompare(b.heading));
      const home = this.UserPOI.find((poi) => poi.heading as DefaultPoiEnum === DefaultPoiEnum.HOME);
      const work = this.UserPOI.find((poi) => poi.heading as DefaultPoiEnum === DefaultPoiEnum.WORK);
      const removePOI = [DefaultPoiEnum.HOME, DefaultPoiEnum.WORK];
      this.UserPOI = this.UserPOI.filter((poi) => !removePOI.includes(poi.heading as DefaultPoiEnum));
      if (work) {
        this.UserPOI.unshift(work);
      }
      if (home) {
        this.UserPOI.unshift(home);
      }
    },
  },
  mounted() {
    this.setPoiCards();
  },
  watch: {
    getPoi() {
      this.UserPOI = [];
      this.OrganizationPOI = [];
      this.setPoiCards();
    },
  },
});
