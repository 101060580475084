
import Vue from 'vue';
import OrganismPoiList, { ORGANISM_NAME as ORGANISM_POI_LIST } from '@/components/organisms/POI/OrganismPoiList.vue';

import OrganismCreatePoi, { ORGANISM_NAME as ORGANISM_CREATE_POI } from '@/components/organisms/POI/OrganismCreatePoi.vue';
import OrganismEditPoi, { ORGANISM_NAME as ORGANISM_EDIT_POI } from '@/components/organisms/POI/OrganismEditPoi.vue';

import { mapActions, mapGetters } from 'vuex';
import PoiTypesEnum from '@/enums/geolocation/PoiTypesEnum';

export default Vue.extend({
  name: 'TemplateMyLocations',
  components: {
    OrganismPoiList,
    OrganismCreatePoi,
    OrganismEditPoi,
  },
  computed: {
    ...mapGetters('user', ['getUserId', 'site', 'b2cFree']),
    ...mapGetters('poi', ['getPoi']),
    getComponentName(): string {
      if (this.$route.path.indexOf('create') >= 0) return ORGANISM_CREATE_POI;
      if (this.$route.path.indexOf('edit') >= 0) return ORGANISM_EDIT_POI;

      return ORGANISM_POI_LIST;
    },
  },
  methods: {
    ...mapActions('poi', ['fetchPoi']),
  },
  mounted() {
    if (!this.getPoi.length) {
      this.fetchPoi({ ownerId: this.getUserId, type: PoiTypesEnum.USER });
      if (!this.b2cFree) this.fetchPoi({ type: PoiTypesEnum.GLOBAL });
    }
  },
});
