
import Vue, { PropType } from 'vue';
import AtomBadge, { BadgeVariant } from '@/components/atoms/AtomBadge.vue';
import AtomCircularIcon, { CircularIconVariant } from '@/components/atoms/AtomCircularIcon.vue';
import MoleculeCard, { CardVariant } from '@/components/molecules/cards/MoleculeCard.vue';

export default Vue.extend({
  name: 'MoleculeDetailsCard',
  components: {
    AtomBadge,
    AtomCircularIcon,
    MoleculeCard,
  },
  data: () => ({
    BadgeVariant,
    CardVariant,
  }),
  props: {
    icon: {
      type: String,
      required: true,
    },
    extra: {
      type: String,
      required: false,
    },
    variant: {
      type: String as PropType<CircularIconVariant>,
      validator: (val: CircularIconVariant) => Object.values(CircularIconVariant).includes(val),
      default: CircularIconVariant.PRIMARY,
    },
  },
  computed: {
    getExtraLabel(): string {
      return (
        this.extra.length > 20
          ? this.extra.slice(0, 20).concat('...')
          : this.extra
      );
    },
  },
});
