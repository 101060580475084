
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/i18n';

import { LOCATION_REGEX } from '@/common/config';

import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomDropdownAddressSearch from '@/components/atoms/AtomDropdownAddressSearch.vue';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import AtomNotification, { NotificationVariant } from '@/components/atoms/AtomNotification.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import MoleculeHereMap from '@/components/molecules/maps/MoleculeHereMap.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';

import { createMarkerBase, createMarker, resizeMap } from '@twogo/here-maps/src/lib/HereMapsService';
import BaseMarkerModel from '@twogo/here-maps/src/lib/models/BaseMarkerModel';
import { CoordinatesModel } from '@/models/common/CoordinatesModel';
import GenericAddressModel from '@/models/geolocation/GenericAddressModel';
import PoiPostModel from '@/models/geolocation/poi/PoiPostModel';

import { browserGeolocation, revGeoCode } from '@/services/geolocation/geolocationService';
import { quicksearch } from '@/api/geolocation/quicksearch/quicksearchApi';

import PoiTypesEnum from '@/enums/geolocation/PoiTypesEnum';

import locationPin from '@/assets/images/icons/icon-pin-start.svg';

export const ORGANISM_NAME = 'OrganismCreatePoi';

export default Vue.extend({
  name: ORGANISM_NAME,
  components: {
    AtomButton,
    AtomNotification,
    AtomTextInput,
    MoleculeHereMap,
    AtomDropdownAddressSearch,
    OrganismPageSubHeader,
  },
  data: () => ({
    name: '',
    comment: '',
    address: undefined as GenericAddressModel | undefined,
    geoSuggestions: [] as GenericAddressModel[],
    hereMap: undefined as any,
    hereMapGroup: undefined as any,
    isNameFieldValid: true,
    isNameInUse: false,
    disableNameInput: false,
    ButtonVariant,
    NotificationVariant,
    PoiTypesEnum,
    inputRequirements: [{
      validator: (input) => LOCATION_REGEX.test(input),
      showOnValid: true,
      description: i18n.t('myLocations.nameValidation').toString(),
    }],
    locationHintMarker: undefined as any,
    isLoading: false,
  }),
  computed: {
    ...mapGetters('user', ['getUserId']),
    ...mapGetters('poi', [
      'getPoi',
      'getHomePoi',
      'isPoiNameInUse',
      'isPoiNameReserved',
      'getPoiById',
    ]),
    isFormValid(): boolean {
      const { name, isNameFieldValid, isNameInUse, address } = this;
      return name !== '' && isNameFieldValid && !isNameInUse && address !== undefined;
    },
    isPoiNameTaken(): boolean {
      const { name, isPoiNameInUse } = this;
      return name && isPoiNameInUse(name);
    },
    getMarker(): BaseMarkerModel[] {
      const { address } = this;
      const markers = [] as BaseMarkerModel[];

      if (address) {
        markers.push(createMarkerBase(locationPin, address.position, 'create-POI'));
      }

      return markers;
    },
  },
  methods: {
    ...mapActions('poi', ['addPoi']),
    async getGeoSuggestions(location: string): Promise<void> {
      if (location.length >= 3) {
        const { data } = await quicksearch(location);
        this.geoSuggestions = data;
      } else {
        this.geoSuggestions = [];
      }
    },
    async searchAddressByCoordinates(coordinates: CoordinatesModel): Promise<void> {
      const {
        data: [address],
      } = await revGeoCode({ at: coordinates });

      // this.address = address;
    },
    async localizeMe(): Promise<void> {
      const { success, coordinates, message = '' } = await browserGeolocation();
      if (success && coordinates) {
        this.searchAddressByCoordinates(coordinates);
      } else {
        toast(this.$bvToast, message, ToastVariants.DANGER);
      }
    },
    postPoi(): void {
      this.isLoading = true;
      this.addPoi({
        comment: this.comment,
        name: this.name,
        address: this.address?.address.label,
        location: {
          latitude: this.address?.position.lat,
          longitude: this.address?.position.lng,
        },
        ownerId: this.getUserId,
        type: PoiTypesEnum.USER,
      } as PoiPostModel)
        .then(() => {
          toast(this.$bvToast, this.$t('myLocations.messages.success').toString());
          setTimeout(() => {
            this.$router.push(`/${this.$i18n.locale}/locations`);
          }, 1500);
        })
        .catch(() => {
          toast(
            this.$bvToast,
            this.$t('myLocations.messages.error').toString(),
            ToastVariants.DANGER,
          );
        }).finally(() => {
          this.isLoading = false;
        });
    },
    validateName(status: boolean) {
      this.isNameFieldValid = status;
    },
    locationHover(coordinates) {
      if (this.hereMap) {
        if (!this.locationHintMarker) {
          this.locationHintMarker = createMarker({ coordinates, icon: locationPin as any });
          this.hereMap.addObject(this.locationHintMarker);
        }
        this.locationHintMarker.setGeometry(coordinates);
        this.hereMap.setCenter(coordinates);
        this.hereMap.setZoom(14);
      }
    },
    locationHoverEnd() {
      if (this.locationHintMarker) {
        this.hereMap.removeObject(this.locationHintMarker);

        this.locationHintMarker = undefined as any;
        resizeMap(this.hereMap, this.hereMapGroup);
      }
    },
  },
  watch: {
    name(value, prevValue) {
      if (prevValue !== '' && (this.isPoiNameInUse(value) || this.isPoiNameReserved(value))) {
        this.isNameInUse = true;
      } else {
        this.isNameInUse = false;
      }
    },
  },
  async mounted() {
    const { name } = this.$router.currentRoute.params;

    if (name && this.isPoiNameReserved(name)) {
      this.name = name;
      this.disableNameInput = true;
      this.isNameInUse = this.isPoiNameInUse(name);
    } else if (name) {
      this.$router.push(`/${this.$i18n.locale}/locations/create`);
    }
  },
});
